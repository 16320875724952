<script module lang="ts">
	type DialogData = Omit<DialogModalNamespace.Props, 'children' | 'isShowing'> & {
		content: string;
		buttonTitle: string;
	};
	const dialogs = writable<DialogData[]>([]);
	type HideDialog = () => void;

	function hide(dialog: DialogData) {
		dialogs.update((prev) => prev.filter((d) => d !== dialog));
	}

	function show(dialog: DialogData): HideDialog {
		dialogs.update((prev) => [...prev, dialog]);
		return () => hide(dialog);
	}

	export const Dialog = {
		show,
		hide,
	};
</script>

<script lang="ts">
	import DialogModal, {
		type DialogModalNamespace,
	} from '$lib/components/Modal/DialogModal.svelte';
	import ButtonText from '$lib/components/buttons/ButtonText.svelte';
	import TextButton from '$lib/components/buttons/TextButton.svelte';
	import { writable } from 'svelte/store';
</script>

{#each $dialogs as dialog (dialog)}
	<!-- content here -->
	<DialogModal {...dialog} isShowing preventCloseOnOutsideClick>
		{dialog.content}
		<TextButton
			fullWidth
			backgroundColour="secondary-green-contrast"
			onclick={() => {
				hide(dialog);
			}}
		>
			<ButtonText>{dialog.buttonTitle}</ButtonText>
		</TextButton>
	</DialogModal>
{/each}
